
import { Component, Prop, Vue } from "vue-property-decorator";
import BackButton from "@/components/common/BackButton.vue";
import { PageTitleProps } from "@/types/layout/pageTitle";

@Component({
  name: "PageTitle",
  components: { BackButton },
})
export default class PageTitle extends Vue {
  @Prop({ default: "" })
  private title!: PageTitleProps["title"];
  @Prop({ default: true })
  private shadow!: PageTitleProps["shadow"];
  @Prop({ default: () => [] })
  private titleClasses!: PageTitleProps["titleClasses"];
  @Prop({
    default: () => ({
      show: false,
      text: "",
    }),
  })
  private backButtonConfig!: PageTitleProps["backButtonConfig"];
  @Prop({ default: null })
  private rightOptionComponentConfig!: PageTitleProps["rightOptionComponentConfig"];
  @Prop({ default: null })
  private leftOptionComponentConfig!: PageTitleProps["leftOptionComponentConfig"];

  get pageTitleClasses(): string[] {
    let classes = ["page-title-wrapper", this.$mq];
    if (this.shadow) {
      classes.push("shadow-bottom");
    }
    return classes;
  }

  get titleStyles() {
    let styles: { [key: string]: string } = {};
    if (this.isMobile) {
      styles.maxWidth = window.innerWidth - 50 + "px";
    }
    return styles;
  }
  get isMobile() {
    return this.$mq === "mobile";
  }
}
