
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "BackButton",
  components: {},
})
export default class BackButton extends Vue {
  @Prop({ default: "" })
  private text!: string;
  @Prop({ default: undefined })
  private onBack!: () => any;

  back() {
    //Custom defined
    if (this.onBack) {
      this.onBack();
    } else {
      //Or default
      this.$router.back();
    }
  }

  get tooltipText() {
    return this.text ? this.text : "Go Back";
  }
  get displayText() {
    return this.$mq !== "mobile";
  }
}
